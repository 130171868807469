<template>
  <div class="homePage sc-width">
    <div class="top">
      <router-link to="/samples" class="">
        <div class="back-request">
          <div class="pic"><img src="@/assets/back.png" alt="" /></div>
          <div class="word">Return to Requests</div>
        </div>
      </router-link>
    </div>

    <div class="main sc-width">
      <div class="title">Sample Request Detail</div>
      <div class="body">
        <div class="left">
          <div class="sub-title">Request User Info</div>
          <div class="divided"></div>
          <div class="content">
            <div class="item">
              <div class="label">Request User</div>
              <div class="value">
                {{ sampleForm.first_name }}
              </div>
            </div>
            <div class="item">
              <div class="label">Request User Company</div>
              <div class="value">
                {{ sampleForm.company_name }}
              </div>
            </div>
            <div class="item">
              <div class="label">Request Date</div>
              <div class="value">
                {{ sampleForm.created_at | shortName }}
              </div>
            </div>
            <div class="item">
              <div class="label">Address</div>
              <div class="value">
                {{ sampleForm.address }}
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="sub-title">Request Product Info</div>
          <div class="divided"></div>
          <div class="content">
            <div class="item">
              <div class="label">Product Name</div>
              <div class="value">
                {{ sampleForm.marketing_name }}
              </div>
            </div>
            <div class="item">
              <div class="label">Factory Name</div>
              <div class="value">
                {{ sampleForm.factory_name }}
              </div>
            </div>
            <div class="item">
              <div class="label">Sample Size</div>
              <div class="value">
                {{ sampleForm.sample_size }}
              </div>
            </div>
            <div class="item">
              <div class="label">Purpose</div>
              <div class="value">
                {{ sampleForm.purpose }}
              </div>
            </div>
            <div class="item">
              <div class="label">Documents</div>
              <div class="value">
                <div
                  v-if="sampleForm.documents.length"
                  style="background-color:#f7963317;border-radius:4px;"
                >
                  <p
                    class="tip doc"
                    v-for="(doc, index) in sampleForm.documents"
                    :key="index"
                  >
                    {{ doc.name }}
                    <a :href="doc.url" target="_blank"
                      ><Icon type="md-open"
                    /></a>
                  </p>
                </div>
                <div v-else>N/A</div>
              </div>
            </div>
            <div class="item">
              <div class="label">remarks</div>
              <div class="value">
                {{ sampleForm.remarks }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="operate-btn" v-if="sampleForm.state === 0">
        <div class="reject" @click="rejectDo(sampleForm)">Reject Request</div>
        <div class="accept" @click="acceptDo(sampleForm)">Accept Request</div>
      </div>
    </div>
    <Modal v-model="rejectPop" footer-hide title="Reason for Rejecting">
      <Input
        :rows="5"
        type="textarea"
        placeholder="Please state the reason why you are rejecting this request..."
        v-model="samForm.reject_reason"
      />
      <div style="padding-top:15px;text-align:right">
        <Button
          size="small"
          type="primary"
          style="color:#f95858;background:transparent;border:1px solid #f95858"
          @click="(rejectPop = false), (samForm.reject_reason = '')"
          >Cancel</Button
        >
        &nbsp;&nbsp;
        <Button
          size="small"
          type="primary"
          :loading="confLoading"
          @click="sendReject"
          >Confirm</Button
        >
      </div>
    </Modal>

    <Modal v-model="acceptPop" footer-hide title="Accept Request">
      <h4 style="padding:10px 0;">Are you sure to accept it ?</h4>
      <div style="text-align:right">
        <Button type="primary" :loading="confLoading" @click="sendRejectAcc"
          >Confirm</Button
        >
      </div>
    </Modal>
  </div>
</template>
<script>
import api from "@/api";

const {
  _queryDocumentList,
  _querySample,
  _querySampleRelatedInfo,
  _checkSample
} = api;

export default {
  created() {
    _querySampleRelatedInfo()
      .then(response => {
        const { data } = response;
        this.sampleInfo = data[0];
      })
      .catch(error => {
        console.log(error);
      });

    _queryDocumentList({
      limit: 999
    })
      .then(response => {
        const {
          data: { data }
        } = response;

        this.documentList = data;
      })
      .catch(error => {
        console.log(error);
      });

    this.getSampleInfo();
  },
  computed: {
    percent() {
      return this.form2.composition_percent.reduce((all, next) => {
        return all + Number(next.composition);
      }, 0);
    }
  },
  filters: {
    shortName(val) {
      if (val) return val.substring(0, 10);
    }
  },
  data() {
    return {
      rejectPop: false,
      curItemRejected: null,
      acceptPop: false,
      delPop: false,
      confLoading: false,
      numfLoading: false,
      showInquire: false,
      editLoading: false,
      editNumber: false,
      editNumberPop: false,
      samForm: {
        state: null,
        reject_reason: null,
        tracking_number: null
      },
      isfromReq: this.$route.query.from === "requestlists",
      loading: true,
      createLoading: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      sampleForm: {
        first_name: "",
        sample_size: null,
        documents: "",
        purpose: null,
        remark: null
      },
      documentList: [],
      sampleInfo: {}
    };
  },

  methods: {
    rejectDo(item) {
      this.rejectPop = true;
      this.curItemRejected = item;
    },
    acceptDo(item) {
      this.acceptPop = true;
      this.curItemRejected = item;
    },
    sendRejectAcc() {
      this.confLoading = true;
      _checkSample({
        id: this.curItemRejected.id,
        state: 1
      })
        .then(() => {
          this.$Message.success("Submit Success");
          this.acceptPop = false;
          this.$router.push("/samples");
          // this.getSamples();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.confLoading = false;
        });
    },
    sendReject() {
      if (!this.samForm.reject_reason) {
        this.$Message.warning("Please fill reason");
        return;
      }
      this.confLoading = true;
      _checkSample({
        id: this.curItemRejected.id,
        state: -1,
        reject_reason: this.samForm.reject_reason
      })
        .then(() => {
          this.$Message.success("Submit Success");
          this.rejectPop = false;
          this.samForm.reject_reason = "";
          // this.getSamples();
          this.$router.push("/samples");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.confLoading = false;
        });
    },
    getSampleInfo() {
      const id = this.$route.params.id;
      this.createLoading = true;
      _querySample({ id })
        .then(({ data }) => {
          this.sampleForm.id = data.id;
          this.sampleForm.company_name = data.company.company_name;
          this.sampleForm.website = data.company.website;
          this.sampleForm.first_name =
            data.user.first_name + " " + data.user.last_name;
          this.sampleForm.established_year = data.company.established_year;
          this.sampleForm.marketing_name = data.product.marketing_name;
          this.sampleForm.created_at = data.created_at;
          this.sampleForm.descriptive_name = data.product.descriptive_name;
          this.sampleForm.factory_name = data.product.factory?.factory_name;
          this.sampleForm.sample_size = data.sample_size;
          this.sampleForm.purpose = data.purpose;
          this.sampleForm.state = data.state;
          this.sampleForm.documents = data.sample_request_documents;
          this.sampleForm.remark = data.remark || "N/A";
          this.sampleForm.address =
            (data.company.company_street_address || "") +
            " " +
            (data.company.company_city_address || "") +
            " " +
            (data.company.company_state_address || "") +
            " " +
            data.company.company_country_address_desc;
          this.loading = false;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.createLoading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.homePage {
  background: #ffffff;
  margin: 76px auto 0 auto;
}

.sc-width {
}

.top {
  .back-request {
    width: 220px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    top: 39px;
    .pic {
      width: 8px;
      height: 10px;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        left: 0px;
      }
    }

    .word {
      position: relative;
      top: -2px;
      font-size: 20px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
  }
}

.main {
  margin-top: 90px;
  margin-bottom: 50px;
  .title {
    height: 90px;
    background: linear-gradient(90deg, #a25d72, #2d3b81);
    opacity: 0.9;
    border-radius: 5px;
    font-size: 28px;

    font-weight: 700;
    color: #ffffff;
    line-height: 18px;
    padding: 35px 0 0 50px;
  }
}

.operate-btn {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  div {
    cursor: pointer;
  }
  .reject {
    width: 180px;
    height: 34px;
    border: 1px solid #ef7f21;
    border-radius: 5px;
    font-size: 18px;

    font-weight: 400;
    color: #ef7f21;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
    margin-right: 20px;
  }

  .accept {
    width: 180px;
    height: 34px;
    background: linear-gradient(90deg, #f5a210, #ef8126);
    border-radius: 5px;
    font-size: 18px;

    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    text-align: center;
    padding-top: 7px;
  }
}

.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}

.wide {
  width: 1000px;
  margin: 0 auto;

  .doc {
    font-size: 16px;
    color: #ff6600;
    padding: 2px 6px;
    line-height: 24px;

    a {
      color: #ff6600;
    }
  }

  .main-title {
    text-align: center;
    color: #000;
    margin: 30px 0;
  }

  .divide {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .item-tips {
    position: absolute;
    right: 0;
    font-size: 12px;

    .info {
      color: #999;
    }

    .link {
      color: @primary-color;
      text-decoration: underline;
    }
  }

  .form-box-group {
    .form-box-title {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 12px;
      color: #515a6e;
      line-height: 1;
      padding: 10px 12px 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .compose {
      display: inline-block;
      width: 100%;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 6px;
    }
  }
}

.remarks {
  border: 1px dotted #dcdee2;
  border-radius: 4px;
  background-color: #f3f3f3;
  padding: 0 8px;
  word-break: break-all;
}

/deep/ .ivu-form-item-content .ivu-input,
/deep/ .ivu-select-default .ivu-select-selected-value {
  font-size: 16px;
  color: #333;
  border: none;
  background-color: #f7963317;
}

/deep/ .ivu-select-selection {
  border: none;

  .ivu-icon {
    display: none;
  }

  &:hover .ivu-icon {
    display: none;
  }
}

/deep/ .longLabel .ivu-form-item-label {
  padding: 3px 6px 3px 0;
}

/deep/ .inpTextarea textarea {
  resize: none;
  width: 680px;
}

.body {
  display: flex;

  .left {
    margin-right: 50px;
    margin-left: 50px;

    .sub-title {
      font-size: 24px;

      font-weight: 500;
      color: #323538;
      line-height: 18px;
      margin-top: 40px;
    }

    .divided {
      width: 600px;
      height: 1px;
      background: #999999;
      margin-top: 13px;
      margin-bottom: 30px;
    }

    .content {
      .item {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .label {
          padding-top: 8px;
          padding-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #52575c;
          line-height: 18px;
        }

        .value {
          width: 350px;
          height: 35px;
          /*background: #FFF7F1;*/
          border-radius: 5px;
          background-color: #fff7f1;
          padding: 6px 0 0 10px;

          input {
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            background-color: #fff7f1;
          }
        }
      }
    }
  }

  .right {
    .sub-title {
      font-size: 24px;

      font-weight: 500;
      color: #323538;
      line-height: 18px;
      margin-top: 40px;
    }

    .divided {
      width: 600px;
      height: 1px;
      background: #999999;
      margin-top: 13px;
      margin-bottom: 30px;
    }

    .content {
      .item {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .label {
          padding-top: 8px;
          padding-right: 10px;
          font-size: 16px;

          font-weight: 400;
          color: #52575c;
          line-height: 18px;
        }

        .value {
          width: 350px;
          height: 35px;
          background: #fff7f1;
          border-radius: 5px;
          padding: 6px 0 0 10px;

          input {
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            background-color: #fff7f1;
          }
        }
      }
    }
  }
}
</style>
